<template>
  <div
    :class="template"
    class="he-ui-progress-circle"
    role="progressbar"
    :aria-valuenow="data"
    aria-valuemin="0"
    aria-valuemax="60"
    :style="'--value:' + data" />
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      required: true
    },
    template: {
      type: String,
      required: true,
      validator: function (value) {
        return ['default', 'white'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$fg: #84A9AC;
$bg: #2E4F64;
@keyframes growProgressBar {
  0%, 33% { --pgPercentage: 0; }
  100% { --pgPercentage: var(--value); }
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

.he-ui-progress-circle {
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: 12.8rem;
  height: 12.8rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 2rem;
  color: $fg;

  &.default {
    background: radial-gradient(closest-side, #29465E 75%, transparent 0 100%, #2E4F64 0),
    conic-gradient($fg calc(var(--pgPercentage) * 100% / 60), $bg 0);
  }
  &.white {
    background: radial-gradient(closest-side, #fff 75%, transparent 0 100%, #2E4F64 0),
    conic-gradient($fg calc(var(--pgPercentage) * 100% / 60), $bg 0);
  }
  @media (max-width: 1180px) {
    width: 10rem;
    height: 10rem;
    font-size: 1.5rem;
  }
}

.he-ui-progress-circle {
  &:before {
    counter-reset: percentage var(--value);
    content: counter(percentage) ' min';
    font-weight: 700;
  }
  &.default {
    &:before {
      color: #fff;
    }
  }
  &.white {
    &:before {
      color: #000;
    }
  }
}
</style>

<template>
  <div class="he-ui-time-period">
    <div class="he-ui-time-period-header">
      {{ $t(data.label) }}
    </div>
    <TimeCircle
      :data="data.value"
      template="white"/>
    <div class="he-ui-time-period-body mt-4">
      {{ data.text }}
    </div>
  </div>
</template>

<script>
import TimeCircle from '@/components/statistics/TimeCircle'

export default {
  components: {
    TimeCircle
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-time-period {
  background: white;
  padding: 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;

  .he-ui-time-period-header {
    text-transform: uppercase;
    border: 1px solid #000;
    padding: 3%;
  }
}
</style>

<template>
  <div class="he-ui-statistics-details-wrapper w-100 h-100">
    <Phase
      showTimeCircle
      showButton
      :data="phase"/>
    <time-period-report
      v-for="period in timePeriods"
      :key="period.id"
      :data="period"/>
  </div>
</template>

<script>
import Phase from '@/components/Phase'
import TimePeriodReport from '@/components/statistics/TimePeriodReport'

export default {
  components: {
    Phase,
    TimePeriodReport
  },
  data () {
    return {
      timePeriods: [
        { id: 1, label: 'main.previous_day', value: '20', text: 'Lorem ipusm dolor sit amet, consecterur adipscing etil. Maecenas eget tristique metus, at placerat lectus.' },
        { id: 2, label: 'main.previous_month', value: '50', text: 'Lorem ipusm dolor sit amet, consecterur adipscing etil. Maecenas eget tristique metus, at placerat lectus.' },
        { id: 2, label: 'main.previous_year', value: '38', text: 'Lorem ipusm dolor sit amet, consecterur adipscing etil. Maecenas eget tristique metus, at placerat lectus.' }
      ],
      phase: {
        id: 1,
        active: true,
        value: '30',
        src: require('../../assets/statistics/admision.svg'),
        alt: 'admission',
        tag: this.$t('main.admission')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-statistics-details-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5%;
}

</style>
